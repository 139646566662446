import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { graphql } from "gatsby";
import { Lead, H2 } from "../../components/Typography";
import { Section } from "../../components/UI";
import Layout from "../../components/Layout";
import { SEO } from "gatsby-plugin-seo";
export const pageQuery = graphql`
  query {
    globalContent: file(sourceInstanceName: { eq: "global" }) {
      childMdx {
        frontmatter {
          businessName
          siteNameShort
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Terms of Use",
  "description": "These Terms of Use explain how we intend this site to be used as well as the restrictions on the use of the content on this site.",
  "effectiveDate": "15 October, 2019"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};

const MDXLayout = ({
  pageContext,
  children
}) => {
  return <Layout>
      <SEO pagePath="/terms-of-use" title={pageContext.frontmatter.title} description={pageContext.frontmatter.description} />
      <Section className="py-12" maxWidth="max-w-3xl">
        {children}
      </Section>
    </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "terms-of-use"
    }}>{`Terms of Use`}</h1>
    <Lead mdxType="Lead">
  In this section, we've outlined how we intend our website to be used and any
  responsibilites you may have as a website visitor.
    </Lead>
    <H2 mdxType="H2">Agreement Between User and {props.data.globalContent.childMdx.frontmatter.siteNameShort}</H2>
    <p>The {props.data.globalContent.childMdx.frontmatter.siteNameShort} website (the "Site") is comprised of various web pages operated by {props.data.globalContent.childMdx.frontmatter.businessName} ("{props.data.globalContent.childMdx.frontmatter.businessName}"). {props.data.globalContent.childMdx.frontmatter.siteNameShort} is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of {props.data.globalContent.childMdx.frontmatter.siteNameShort} constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</p>
    <p>{props.data.globalContent.childMdx.frontmatter.siteNameShort} is a Company Site designed to describe the services and solutions that {props.data.globalContent.childMdx.frontmatter.businessName} offers.</p>
    <h2 {...{
      "id": "privacy"
    }}>{`Privacy`}</h2>
    <p>Your use of {props.data.globalContent.childMdx.frontmatter.siteNameShort} is subject to our Privacy Policy. Please <a to="/privacy-policy">review our Privacy Policy</a>, which also governs the Site and informs users of our data collection practices.</p>
    <h2 {...{
      "id": "electronic-communications"
    }}>{`Electronic Communications`}</h2>
    <p>Visiting {props.data.globalContent.childMdx.frontmatter.siteNameShort} or sending emails to {props.data.globalContent.childMdx.frontmatter.businessName} constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>
    <h2 {...{
      "id": "children-under-thirteen"
    }}>{`Children Under Thirteen`}</h2>
    <p>{props.data.globalContent.childMdx.frontmatter.siteNameShort} is not intended for, and does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use {props.data.globalContent.childMdx.frontmatter.siteNameShort} only with permission of a parent or guardian.</p>
    <h2 {...{
      "id": "links-to-third-party-sitesthird-party-services"
    }}>{`Links to Third Party Sites/Third Party Services`}</h2>
    <p>{props.data.globalContent.childMdx.frontmatter.siteNameShort} may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of {props.data.globalContent.childMdx.frontmatter.businessName} and {props.data.globalContent.childMdx.frontmatter.businessName} is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. {props.data.globalContent.childMdx.frontmatter.businessName} is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by {props.data.globalContent.childMdx.frontmatter.businessName} of the site or any association with its operators.</p>
    <p>Certain services made available via {props.data.globalContent.childMdx.frontmatter.siteNameShort} are delivered by third party sites and organizations. By using any product, service or functionality originating from the {props.data.globalContent.childMdx.frontmatter.siteNameShort} domain, you hereby acknowledge and consent that {props.data.globalContent.childMdx.frontmatter.businessName} may share such information and data with any third party with whom {props.data.globalContent.childMdx.frontmatter.businessName} has a contractual relationship to provide the requested product, service or functionality on behalf of {props.data.globalContent.childMdx.frontmatter.siteNameShort} users and customers.</p>
    <h2 {...{
      "id": "no-unlawful-or-prohibited-useintellectual-property"
    }}>{`No Unlawful or Prohibited Use/Intellectual Property`}</h2>
    <p>You are granted a non-exclusive, non-transferable, revocable license to access and use {props.data.globalContent.childMdx.frontmatter.siteNameShort} in accordance with these terms of use. As a condition of your use of the Site, you warrant to {props.data.globalContent.childMdx.frontmatter.businessName} that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
    <h2 {...{
      "id": "international-users"
    }}>{`International Users`}</h2>
    <p>The Service is controlled, operated and administered by {props.data.globalContent.childMdx.frontmatter.businessName} from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the {props.data.globalContent.childMdx.frontmatter.businessName} Content accessed through {props.data.globalContent.childMdx.frontmatter.siteNameShort} in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
    <h2 {...{
      "id": "indemnification"
    }}>{`Indemnification`}</h2>
    <p>You agree to indemnify, defend and hold harmless {props.data.globalContent.childMdx.frontmatter.businessName}, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. {props.data.globalContent.childMdx.frontmatter.businessName} reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with {props.data.globalContent.childMdx.frontmatter.businessName} in asserting any available defenses.</p>
    <h2 {...{
      "id": "liability-disclaimer"
    }}>{`Liability Disclaimer`}</h2>
    <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. {props.data.globalContent.childMdx.frontmatter.businessName}, PLLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
    <p>{props.data.globalContent.childMdx.frontmatter.businessName} AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. {props.data.globalContent.childMdx.frontmatter.businessName} AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
    <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. {props.data.globalContent.childMdx.frontmatter.businessName} AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL {props.data.globalContent.childMdx.frontmatter.businessName} AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF {props.data.globalContent.childMdx.frontmatter.businessName} OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>
    <h2 {...{
      "id": "terminationaccess-restriction"
    }}>{`Termination/Access Restriction`}</h2>
    <p>{props.data.globalContent.childMdx.frontmatter.businessName} reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of DE and you hereby consent to the exclusive jurisdiction and venue of courts in DE in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
    <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and {props.data.globalContent.childMdx.frontmatter.businessName} as a result of this agreement or use of the Site. performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by {props.data.globalContent.childMdx.frontmatter.businessName} with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
    <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and {props.data.globalContent.childMdx.frontmatter.businessName} with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and {props.data.globalContent.childMdx.frontmatter.businessName} with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>
    <h2 {...{
      "id": "changes-to-terms"
    }}>{`Changes to Terms`}</h2>
    <p>{props.data.globalContent.childMdx.frontmatter.businessName} reserves the right, in its sole discretion, to change the Terms under which {props.data.globalContent.childMdx.frontmatter.siteNameShort} is offered. The most current version of the Terms will supersede all previous versions. {props.data.globalContent.childMdx.frontmatter.businessName} encourages you to periodically review the Terms to stay informed of our updates.</p>
    <h3 {...{
      "id": "effective-date"
    }}>{`Effective Date`}</h3>
    <p>{props.pageContext.frontmatter.effectiveDate}</p>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      